















import { Component, Mixins } from 'vue-property-decorator';

import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
  },
})
export default class RequestMaintenance extends Mixins(View) {
  private formData: FormConstructorData | null = null;

  mounted() {
    this.formData = new FormConstructorData([
      {
        Type: ContextItemType.EnquiryTargetProfile,
        Id: '8c041c45-73fc-4334-8cc3-097c6e368be3',
      },
    ]);
  }
}
